import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import candidateTermsEN from '../assets/pdf/Noroutine_Talent_Scouting_Terms_Candidate_EN_03_2020.pdf'
import candidateTermsDE from '../assets/pdf/Noroutine_Talent_Scouting_Terms_Candidate_DE_03_2020.pdf'

const TermsOfService = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Terms of Service"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Terms of Service" 
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="privacy-policy-content">
<p>We provide our placement services to you on below terms (English and German versions)</p>
                        <ul>
                                <li><i><a href={candidateTermsDE} target="_blank" rel="noreferrer">AGB (Allgemeine Geschäftsbedingungen)
Vorstellung von Kandidaten für Kunden für direkte Beschäftigung / Engagement</a></i></li>
                                <li><i><a href={candidateTermsEN} target="_blank" rel="noreferrer">Standard Terms of Business
Introduction of Candidates to Clients for Direct Employment/Engagement
</a></i></li>
                        </ul>

<p>
The above general terms and conditions regulate the contractual relationship between you as an applicant or a candidate actively proposed by Noroutine to another company and Noroutine GmbH who will act as a recruiter for you.
Our placement activities, advice and the use of our online offer are COMPLETELY FREE OF CHARGE for you as an applicant. You give us a free order to mediate as an applicant within the meaning of §662 BGB.

Of course, we are also sales and profit-oriented. However, we do not receive our commission, contributions or other costs from you, but from your future employer and only provide a cost, commission or contribution calculation to companies that use our services and / or our job posting portal for publications.
</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfService;